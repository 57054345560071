/* eslint-disable keyword-spacing */
import { Component, DestroyRef, Inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OKTA_AUTH } from '@okta/okta-angular';
// eslint-disable-next-line @typescript-eslint/naming-convention
import OktaAuth from '@okta/okta-auth-js';
import { interval } from 'rxjs';

import { UserInfo } from '../header-toolbar/header-toolbar.component';

import { CarouselService } from './carousel.service';

/**
 * ```<sh-carousel>``` is a list-like component used to display the images in carousel
 * in carousel.
 */
@Component({
	selector: 'sh-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
	@Input() public imageList: string[] = [];
	@Input() public isSelfRegisteredUser = true;
	public currentImageIndex = 0;

	private carouselInterval$ = interval(4000);
	public firstName = this.carouselService.firstName$;
	constructor(
		@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
		private carouselService: CarouselService,
		private readonly destroyRef: DestroyRef
	) {}
	public async ngOnInit(): Promise<void> {
		if (this.carouselService.firstName$.getValue() === '') {
			const userInfo = <UserInfo>(<unknown>await this.oktaAuth.getUser());
			this.carouselService.firstName$.next(userInfo.given_name);
		}
		this.carouselInterval$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.currentImageIndex = ++this.currentImageIndex % this.imageList.length;
		});
	}
}
