import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

import { MyAccountSideNavComponent } from './my-account-side-nav.component';

@NgModule({
	declarations: [MyAccountSideNavComponent],
	exports: [MyAccountSideNavComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatButtonModule,
		MatDividerModule,
		MatListModule,
		RouterModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		TranslocoModule
	]
})
export class MyAccountSideNavModule {}
