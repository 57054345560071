import { Component, Input } from '@angular/core';

@Component({
	selector: 'sh-my-account-side-nav',
	templateUrl: './my-account-side-nav.component.html',
	styleUrls: ['./my-account-side-nav.component.scss']
})
export class MyAccountSideNavComponent {
	@Input() public navigateTo!: string;
	public showSideNavItem = false;
}
