import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs';

import { FormTypes } from '../dialog-box-ignite/dialog-box.model';

import {
	AccountInfoDto,
	industryOptions,
	interestsOptions,
	ProgProfileFormDataDetails,
	relationshipOptions
} from './progressive-profile-dialog.model';

@Component({
	selector: 'sh-progressive-profile-dialog',
	templateUrl: './progressive-profile-dialog.component.html',
	styleUrls: ['./progressive-profile-dialog.component.scss']
})
export class ShProgressiveProfileDialogComponent implements OnInit {
	public formGroup: FormGroup = new FormGroup({});
	public formData: ProgProfileFormDataDetails[] = [];
	public formType = FormTypes;
	public interestsOptions = interestsOptions;
	public relationshipOptions = relationshipOptions;
	private destroy$: Subject<void> = new Subject<void>();
	constructor(
		private formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public progFormData: ProgProfileFormDataDetails[],
		public dialogRef: MatDialogRef<ShProgressiveProfileDialogComponent>
	) {}

	// HostListener to listen for the "Enter" keypress and trigger form validation
	@HostListener('window:keydown.enter', ['$event'])
	public handleEnterKey(event: KeyboardEvent): void {
		event.preventDefault();
		this.formGroup.markAllAsTouched();
		if (this.formGroup.valid) {
			this.onSubmit();
		}
	}
	public ngOnInit(): void {
		this.formGroup = this.formBuilder.group({});
		this.formData = this.progFormData;
		this.formData?.forEach((formControlDetail) => {
			if (formControlDetail.type === 'checkbox') {
				const checkboxFormGroup = this.formBuilder.group({});
				if (formControlDetail.name === 'interests') {
					this.interestsOptions.forEach((option) => {
						checkboxFormGroup.addControl(option.name, new FormControl(false));
					});
				} else if (formControlDetail.name === 'relationToShure') {
					this.relationshipOptions.forEach((option) => {
						checkboxFormGroup.addControl(option.name, new FormControl(false));
					});
				} else if (formControlDetail.name === 'tc') {
					checkboxFormGroup.addControl('isTcAccepted', new FormControl(false, formControlDetail.validators));
				}
				this.formGroup.addControl(formControlDetail.name, checkboxFormGroup);
			} else {
				this.formGroup.addControl(formControlDetail.name, new FormControl(''));
			}
			if (formControlDetail.disabled) {
				this.formGroup.controls[formControlDetail.name].disable();
			}
		});
	}

	/* This method is called when the user selects an industry
	 * and must set the respective industry details data for selection
	 * and enable industry details form control
	 */
	public segmentSelected(event: MatSelectChange): void {
		const industryDetailSelected = industryOptions.find((industryItem) => industryItem.value === event.value)
			?.industryDetail;
		const index = this.formData?.findIndex((formRow) => formRow.name === 'segmentDetails');
		this.formData[index].data = industryDetailSelected;
		this.formGroup.get('segmentDetails')?.enable();
	}

	// This method is called when the user submits the data
	public onSubmit(): void {
		if (this.formGroup.valid) {
			const formGroupValue = this.formGroup.value;
			const updateAccountData = this.formatData(formGroupValue);
			this.dialogRef.close({ data: updateAccountData });
		}
	}

	/* it must format the form group submitted data into the type of AccoutInfoDto
	 * and return the formatted data.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public formatData(formGroupValue: any): AccountInfoDto {
		Object.keys(formGroupValue).forEach((key) => {
			if (key === 'interests' || key === 'relationToShure') {
				const checkboxSelectedOptions = Object.entries(formGroupValue[key]).filter(
					([, value]) => value === true
				);
				const checkboxSelectedOptionsKeys = checkboxSelectedOptions.map(([key]) => key);

				if (key === 'interests') {
					formGroupValue[key] = this.interestsOptions
						.filter((interest) => checkboxSelectedOptionsKeys.includes(interest.name))
						.map((filteredInterest) => filteredInterest.value);
				} else {
					formGroupValue[key] = this.relationshipOptions
						.filter((relationship) => checkboxSelectedOptionsKeys.includes(relationship.name))
						.map((filteredRelations) => filteredRelations.value);
				}
			}
			if (key === 'departments') {
				formGroupValue.department = formGroupValue.departments;
				delete formGroupValue.departments;
			}
			if (key === 'segments') {
				formGroupValue.segmentDetails = formGroupValue.segments;
				delete formGroupValue.segments;
			}
			if (formGroupValue[key] === '' || formGroupValue[key].length <= 0) {
				delete formGroupValue[key];
			}
		});
		return formGroupValue;
	}
}
