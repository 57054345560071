/* eslint-disable  @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, ColumnVisibleEvent, GridApi, GridOptions } from 'ag-grid-community';

import { AgGridResponsiveWrapperComponent } from '../ag-grid-responsive-wrapper/ag-grid-responsive-wrapper.component';

import { AgGridTableService } from './ag-grid-table.service';

@Component({
	selector: 'sh-ag-grid-table',
	standalone: true,
	imports: [CommonModule, AgGridModule, AgGridResponsiveWrapperComponent],
	templateUrl: './ag-grid-table.component.html',
	styleUrls: ['./ag-grid-table.component.scss']
})
export class AgGridTableComponent implements OnInit {
	@Input() public componentGridOptions!: GridOptions;
	@Input() public rowData!: any;
	@Input() public columnDefs!: ColDef[];
	@Input() public gridApi!: GridApi;
	/**
	 * Min height that is only used when the no rows overlay is showing.
	 */
	@Input() public minNoRowsHeight?: number;

	/**
	 * Min height that is only used when the loading overlay is showing.
	 */
	@Input() public minLoadingHeight?: number;

	/**
	 * Min height used when there are no rows available, such as when the no rows or loading overlays are shown.
	 * NOTE: the other 2 more specific min height options take precedence over this one
	 */
	@Input() public minHeight?: number;

	/**
	 * Filter value to apply to the grid.
	 */
	@Input() public filterValue?: string;
	public gridOptions!: GridOptions;
	private baseGridOptions: GridOptions = {
		/**
		 * Column definition applied to columns in a grouping row
		 * https://ag-grid.com/angular-data-grid/grid-options/#reference-rowGrouping-autoGroupColumnDef
		 */
		autoGroupColumnDef: {
			minWidth: 200
		},
		/**
		 * Default column definition for every column in the grid
		 * https://ag-grid.com/angular-data-grid/grid-options/#reference-columns-defaultColDef
		 */
		defaultColDef: {
			resizable: true,
			minWidth: 200,
			wrapHeaderText: true,
			autoHeaderHeight: true,
			flex: 1 // by default, we want all columns to resize proportionally. This can be overridden.
		},
		/**
		 * Get a translated grid string
		 * https://ag-grid.com/angular-data-grid/grid-options/#reference-localisation-getLocaleText
		 */
		getLocaleText: (params) => this.translocoService.translate(`ag-grid.${params.key}`),
		headerHeight: 36,
		rowHeight: 36,
		/**
		 * Setting popup parent to the body is necessary for column menu popups to extend outside of the table when
		 * the table is not tall enough to contain the popup.
		 * https://ag-grid.com/angular-data-grid/grid-options/#reference-accessories-popupParent
		 */
		popupParent: document.querySelector('body'),
		groupDisplayType: 'multipleColumns',
		rowSelection: 'multiple',
		suppressMenuHide: true,
		accentedSort: true,
		animateRows: true,
		onModelUpdated: (): void => {
			const api = this.gridApi;
			if (api) {
				const rowCount = api.getDisplayedRowCount();
				this.agGridTableService.setDisableExport(rowCount > 0 ? false : true);
				if (rowCount === 0) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
			}
		},
		onColumnVisible: (event: ColumnVisibleEvent): void => {
			const allColumns = this.gridApi.getAllDisplayedColumns();
			// Filter out columns that are explicitly hidden using column definitions
			let visibleColumns = allColumns.filter((col) => !col.getColDef().hide);

			// Ensure at least one column remains visible
			if (!event.visible && visibleColumns.length === 0) {
				this.gridApi.setColumnsVisible([event.column ? event.column.getColId() : ''], true);
				return; // Early return to avoid further processing since we're reverting the visibility change
			}

			// Filter out specific columns from grouping logic, e.g., 'action' column
			visibleColumns = visibleColumns.filter((col) => col.getColId() !== 'action');
			const groupedColumns = this.gridApi.getRowGroupColumns();

			if (this.componentGridOptions.rowGroupPanelShow !== 'never') {
				// Adjust row grouping capabilities based on visible and grouped columns
				if (visibleColumns.length - groupedColumns.length < 2) {
					// Disable row grouping for all columns except 'action'
					visibleColumns.forEach((col) => (col.getColDef().enableRowGroup = false));
				} else {
					// Enable row grouping for all columns except 'action'
					allColumns.forEach((col) => {
						if (col.getColId() !== 'action') col.getColDef().enableRowGroup = true;
					});
				}
			}

			this.gridApi.refreshHeader();

			// Check if a filter value has been provided and apply it to the grid quick filter
			if (this.filterValue) {
				// If a filter value exists, apply it to the grid using the AgGridTableService
				this.agGridTableService.applyFilter(this.gridApi, this.filterValue);
			}
		},
		quickFilterMatcher: this.agGridTableService.quickFilterMatcher
	};

	constructor(
		private translocoService: TranslocoService,
		private agGridTableService: AgGridTableService
	) {}

	public ngOnInit(): void {
		this.gridOptions = {
			...this.baseGridOptions,
			...this.componentGridOptions
		};
	}
}
