/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';

import { macAddressComparator } from '../../utils/comparators';

export enum ExportFileTypes {
	CSV = 'csv',
	EXCEL = 'excel'
}

@Injectable({
	providedIn: 'root'
})
export class AgGridTableService {
	private disableExport$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private locale = 'EN-US'; // To be made dynamic after multi-language support

	/**
	 * Get disable export value
	 * @returns boolean: representation of disable export
	 */
	public getDisableExport$(): Observable<boolean> {
		return this.disableExport$;
	}

	/**
	 * Set disable export value
	 * @param disableExport: boolean representation of disableExport
	 */
	public setDisableExport(disableExport: boolean): void {
		this.disableExport$.next(disableExport);
	}

	/**
	 * Filters the table response based on the search text.
	 * @param filterValue - string representation of search text
	 */
	public applyFilter(gridApi: GridApi, filterValue: string): void {
		gridApi.updateGridOptions({ quickFilterText: filterValue });
		gridApi.onFilterChanged();
		if (gridApi.getModel().getRowCount() === 0) {
			gridApi.showNoRowsOverlay();
		} else {
			gridApi.hideOverlay();
			this.setDisableExport(true);
		}
	}

	/**
	 * Creates a column definition for a checkbox selection column with common defaults
	 */
	public createCheckboxColumn(options: ColDef): ColDef {
		return {
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			suppressHeaderMenuButton: true,
			suppressColumnsToolPanel: true, // don't want this column showing up in menus
			suppressMovable: true,
			sortable: false,
			filter: false,
			resizable: false,
			minWidth: 42,
			maxWidth: 42,
			pinned: 'left',
			lockPinned: true,
			lockPosition: true,
			flex: 0,
			...options
		};
	}

	/**
	 * Creates a column definition for a basic text column with sorting and filtering enabled by default
	 */
	public createActionColumn(options: ColDef): ColDef {
		return {
			field: 'action',
			headerName: '',
			sortable: false,
			filter: false,
			suppressMovable: true,
			lockPosition: 'right',
			enableRowGroup: false,
			lockPinned: true,
			singleClickEdit: true,
			suppressColumnsToolPanel: true,
			suppressHeaderMenuButton: true,
			maxWidth: 100,
			...options
		};
	}

	/**
	 * Creates a text column for a MAC address field
	 */
	public createMacAddressColumn(options: ColDef): ColDef {
		return {
			enableRowGroup: false,
			comparator: (a?: string, b?: string) => macAddressComparator(a, b),
			getQuickFilterText: (params) => params.value?.toLowerCase() ?? '',
			...options
		};
	}

	/**
	 * This function which overides the default behaviour of quickFilter
	 * It is called for every single row to check if the search string is present in the row
	 * @param quickFilterParts - string array which contains parts of the search string split by space
	 * @param rowQuickFilterAggregateText - string formed by the concatenation of data in a row into a single string
	 */
	public quickFilterMatcher = (quickFilterParts: string[], rowQuickFilterAggregateText: string): boolean => {
		const collator = new Intl.Collator(this.locale, { sensitivity: 'accent' });
		const nodeData = rowQuickFilterAggregateText.split('\n');
		return quickFilterParts.every((quickFilterItem) =>
			nodeData.some(
				(item: string) =>
					collator.compare(item.toLowerCase(), quickFilterItem.toLowerCase()) === 0 ||
					item.toLowerCase().includes(quickFilterItem.toLowerCase())
			)
		);
	};
}
